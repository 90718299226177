@font-face {
	font-family: 'brands';
	src: url('../../../fonts/FFShamelFamilySansOneBold2.ttf');
}
@font-face {
	font-family: 'reqular';
	src: url('../../../fonts/FFShamelFamilySansOneRegular1.ttf');
}

body {

	font-size: 16px;
	font-weight: 400;
	line-height: 1.65;
	font-style: normal;
	font-family: 'reqular';
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;

	font-family: 'brands';
	line-height: 1.2;
	
}
